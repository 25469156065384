import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAddItem } from '../../hooks/useAddItem';
import { useGetItems } from '../../hooks/useGetItems';
import { useAuth } from "../../hooks/useAuth";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import {
  Button,
  Box,
  TextField,
  Typography,
  Container
} from '@mui/material';

import { EnhancedTable } from "./EnhancedTable";

export const InventoryOLD = () => {
    const { addItem } = useAddItem();
    const navigate = useNavigate();

    const [description, setDescription] = useState("");
    const [itemAmount, setItemAmount] = useState(0);

    const userAuth = useAuth();
    const { items } = useGetItems();

    const onSubmit = (e) => {
        e.preventDefault()
        addItem({description, itemAmount});

        setDescription("");
        setItemAmount("");
    };

    const signUserOut = async () => {
        try {
          await signOut(auth);
          localStorage.clear()
          navigate("/");
        } catch (err) {
          console.error(err);
        }
    };

    return (
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
      >
        <Container maxWidth="xl">
          <div>
              <Typography sx={{ mt: 4, mb: 2 }} variant="h2" component="div">
                  Inventory
              </Typography>

              <form onSubmit={onSubmit}>
                <Box sx={{  margin: "auto", display: 'flex', justifyContent: "center", alignItems: "center" }}>            
                  <TextField sx={{ m: 1 }} id="description" label="Description" variant="outlined" value={description} type="string" onChange={(e) => setDescription(e.target.value)} required/>
                  <TextField sx={{ m: 1 }} id="amount" label="Amount" variant="outlined" value={itemAmount} type="number" onChange={(e) => setItemAmount(e.target.value)} required/>
                  <Button sx={{ m: 1}} variant="contained" type="submit">Add</Button>            
                </Box>
              </form>
                <EnhancedTable rows={items} />
            </div>     
            <div>
                  <Typography sx={{ mt: 4, mb: 2 }} variant="body1" component="div">Signed in as: {userAuth.user.email} </Typography>
                  <Button onClick={signUserOut} variant="contained" >Sign Out</Button>
            </div>
        </Container>
    </Box>
    )

};