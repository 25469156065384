import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import { createTheme } from "./theme";

const root = ReactDOM.createRoot(document.getElementById('__next'));
const theme = createTheme();
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >   
      <CssBaseline /> 
      <App />
    </ThemeProvider> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals