import { useState } from "react";
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useAddItem } from '../../hooks/useAddItem';
import { useGetItems } from '../../hooks/useGetItems';

import {
  Button,
  Box,
  TextField,
  Typography,
  Container,
  Stack,
  SvgIcon
} from '@mui/material';

import { EnhancedTable } from "./EnhancedTable";

export const Inventory = () => {
    const { addItem } = useAddItem();

    const [description, setDescription] = useState("");
    const [itemAmount, setItemAmount] = useState(0);

    const { items } = useGetItems();

    const onSubmit = (e) => {
        e.preventDefault()
        addItem({description, itemAmount});

        setDescription("");
        setItemAmount("");
    };

    return (
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
      >
        <Container maxWidth="xl">
          <div>
              <Typography variant="h4">
                  Inventory
              </Typography>
              <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  <Button
                    color="inherit"
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    )}
                  >
                    Import
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={(
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    )}
                  >
                    Export
                  </Button>
                </Stack>
                <div>
                <Button
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Add
                </Button>
              </div>

              <form onSubmit={onSubmit}>
                <Box sx={{  margin: "auto", display: 'flex', justifyContent: "center", alignItems: "center" }}>            
                  <TextField sx={{ m: 1 }} id="description" label="Description" variant="outlined" value={description} type="string" onChange={(e) => setDescription(e.target.value)} required/>
                  <TextField sx={{ m: 1 }} id="amount" label="Amount" variant="outlined" value={itemAmount} type="number" onChange={(e) => setItemAmount(e.target.value)} required/>
                  <Button sx={{ m: 1}} variant="contained" type="submit">Add</Button>            
                </Box>
              </form>
                <EnhancedTable rows={items} />
            </div>     
        </Container>
    </Box>
    )

};