import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth } from "./pages/auth/index";
import { InventoryOLD } from "./pages/inventory-old/index";
import { Inventory } from "./pages/inventory";
import { Dashboard } from "./pages/dashboard";
import { PrivateRoutesLayout } from "./layouts/PrivateRoutesLayout";
import { Layout as DashboardLayout } from './layouts/dashboard/layout';


import 'simplebar-react/dist/simplebar.min.css';

function App() {

  return (
     <>
      <Router>
        <Routes>
          {/* public routes */}
          <Route path="/" exact element={<Auth />} />
          <Route path="/dashboard" 
          exact element={
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>   
          } />
          <Route path="/inventory" 
          exact element={
            <DashboardLayout>
              <Inventory />
            </DashboardLayout>
          } />

          {/* private routes */}
          <Route element={<PrivateRoutesLayout />}>
            <Route path="/inventory-old" element={<InventoryOLD />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;